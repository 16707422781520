import React, { useEffect } from "react";
import Prism from "prismjs";
import "../pages/style.css";
import "prismjs/themes/prism.css";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-python";
import "prismjs/components/prism-css";
 
const CustomMarkdownRenderer = ({ content, setHeadings }) => {
  const safeContent = typeof content === "string" ? content : "";
  const paddingBottomRegex = /^(.*?)(\^\^\^)(.*?)$/gm;
  const backlinkRegex = /\[(.*?)\]\((.*?)\)/g;
  const customTagRegex = /\$\$(.*?)(\!\[([^\]]+)\]\(([^)]+)\))(.*?)\$\$/gs;
  const headingRegex = /^(#+)\s(.*)/g;
  const listRegex = /^(\*|\d+\.*|>|-|&&|[a-zA-Z]\.)\s(.*)/g;
  const imageRegex = /!\[([^\]]+)\]\(([^)]+)\)/g;
  const boldRegex = /\*\*(.*?)\*\*/g;
  const italicRegex = /~(.*?)~/g;
  const underlineRegex = /__(.*?)__/g;
  const centerRegex = /@@(.*?)@@/g;
  const centerRegex2 = /##(.*?)##/g;
  const centeredItalicRegex = /@_(.*?)_@/g;
  const linkRegex = /\[(.*?)\]\((.*?)\)/g;
  const pinktext = /%%(.*?)%%/g;
  const heading7 = /h7-(.*?)-h7/g;
  const heading9 = /h9-(.*?)-h9/g;
  const customSymbolRegex2 = /\#\#\?\s*([\s\S]*?)\s*\#\#\?/gs;
  const indentParagraphRegex = /\|\|\s\|\|(.*?)$/g;
  const customSymbolRegex = /\/\/\?\s*([\s\S]*?)\s*\/\/\?/gs;
  const videoRegex = /#\[([^\]]+)\]\(([^)]+)\.mp4\)/g;
  const youtubeRegex =
    /#\[([^\]]+)\]\((https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([^\)]+))\)/g;
  const splitTextRegex = /(.*?)!!(.*)/s;
  const customBoxRegex = /\{(.*?)\}/g;
 
  const headings = [];
 
  useEffect(() => {
    setHeadings(headings);
    Prism.highlightAll();
  }, [headings, setHeadings]);
  const processBacklinks = (text) => {
    return text.replace(backlinkRegex, (match, linkText, url) => {
      return `<a href="${url}" class="underline text-blue-500 hover:text-blue-700" target="_blank" rel="noopener noreferrer">${linkText}</a>`;
    });
  };
 
  const escapeHtml = (text) => {
    return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  };
 
  // Split content into code and non-code sections
  const sections = safeContent.split(/(```[\s\S]*?```)/);
 
  const processedSections = sections.map((section, sectionIndex) => {
    // Handle code blocks
    if (section.startsWith("```")) {
      const [firstLine, ...rest] = section.split("\n");
      const language = firstLine.slice(3).trim() || "plaintext";
      const code = rest.slice(0, -1).join("\n"); // Remove the last ``` line
 
      const escapedCode = code
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
 
      return (
        <div
          key={`code-${sectionIndex}`}
          dangerouslySetInnerHTML={{
            __html: `<pre class="language-${language}"><code class="language-${language}">${escapedCode}</code></pre>`,
          }}
        />
      );
    }
 
    return section.split("\n").map((line, lineIndex) => {
      if (!line.trim())
        return <div key={`empty-${sectionIndex}-${lineIndex}`} />;
 
      let parsedLine = line;
 
      parsedLine = processBacklinks(parsedLine);
 
      if (paddingBottomRegex.test(line)) {
        parsedLine = line.replace(
          paddingBottomRegex,
          (match, textBefore, paddingTag, textAfter, text) => {
            const escapedText = escapeHtml(text.trim());
            return `
        <div style="padding-bottom:10px;">
          ${textBefore.trim()} ${textAfter.trim()}
        </div>
      `;
          }
        );
      }
 
      if (customTagRegex.test(line)) {
        parsedLine = line.replace(
          customTagRegex,
          (match, paragraph, imageTag, alt, src, rest) => {
            const paragraphWithLinks = processBacklinks(paragraph);
            const restWithLinks = processBacklinks(rest.trim());
 
            return `
          <div class="flex flex-col lg:flex-row">
            <div class="mb-4 lg:mb-0">${paragraphWithLinks}</div>
            <img class="w-auto lg:block lg:ml-4" src="${src}" alt="${alt}" style="height: auto;" />
            <div class="mt-2">${restWithLinks}</div>
          </div>
        `;
          }
        );
      } else if (headingRegex.test(line)) {
        parsedLine = line.replace(headingRegex, (match, hashes, text) => {
          const level = hashes.length;
          const id = `heading-${sectionIndex}-${lineIndex}`;
          headings.push({ level, text, id });
 
          const headingSize =
            {
              1: "text-3xl md:text-4xl lg:text-4xl font-semibold",
              2: "text-left text-2xl md:text-3xl lg:text-3xl font-semibold",
              3: "text-left text-xl md:text-xl lg:text-2xl 2xl:text-xl font-semibold",
              4: "text-base md:text-lg lg:text-xl font-semibold",
              5: "text-sm md:text-base lg:text-lg font-semibold",
              6: "text-xs md:text-sm lg:text-base font-semibold",
            }[level] || "text-lg md:text-xl lg:text-2xl font-semibold";
 
          return `<h${level} id="${id}" class="${headingSize}">${text}</h${level}>`;
        });
      } else if (listRegex.test(line)) {
        let listType = "";
 
        if (line.startsWith("*")) {
          listType = "square";
        } else if (line.startsWith(">")) {
          listType = "arrow";
        } else if (line.startsWith("&&")) {
          listType = "disc";
        }
 
        parsedLine = line.replace(listRegex, (match, bullet, text) => {
          let bulletSymbol = bullet;
          if (listType === "decimal") {
            const count = bullet.replace(".", "");
            bulletSymbol = `${count}.`;
          } else if (listType === "lower-alpha") {
            bulletSymbol = `${bullet.toLowerCase()}`;
          } else if (listType === "square") {
            bulletSymbol = `■`;
          } else if (listType === "arrow") {
            bulletSymbol = `➤`;
          } else if (listType === "disc") {
            bulletSymbol = `●`;
          }
 
          const [boldText, normalText] = text.split("||", 2);
          const formattedText = `<strong>${boldText.trim()}</strong>${
            normalText
              ? " " +
                normalText
                  .trim()
                  .replace(
                    linkRegex,
                    '<a href="$2" class="underline text-blue-500 hover:text-blue-500">$1</a>'
                  )
              : ""
          }`;
 
          return `
        <li class="custom-list-item ${listType}">
          <span class="bullet 2xl:text-base">${bulletSymbol}</span>
          <span class="text 2xl:text-xl">${formattedText}</span>
        </li>
      `;
        });
 
        parsedLine = `<ul class="custom-list list-${listType}">${parsedLine}</ul>`;
      } else if (indentParagraphRegex.test(line)) {
        parsedLine = line.replace(
          indentParagraphRegex,
          (match, paragraphText) => {
            return `<div class="ml-6">${paragraphText.trim()}</div>`;
          }
        );
      } else if (pinktext.test(line)) {
        parsedLine = line.replace(pinktext, (match, text) => {
          return `<span class="text-[#C62A6D] text-xl font-bold">${text}</span>`;
        });
      } else if (heading7.test(line)) {
        parsedLine = line.replace(heading7, (match, text) => {
          return `<span class="text-5xl font-semibold">${text}</span>`;
        });
      } else if (heading9.test(line)) {
        parsedLine = line.replace(heading9, (match, text) => {
          return `<span class="text-lg font-semibold">${text}</span>`;
        });
      } else if (customBoxRegex.test(line)) {
        parsedLine = line.replace(customBoxRegex, (match, text) => {
          return `<span class="custom-box shadow-md">${text}</span>`;
        });
      } else if (imageRegex.test(line)) {
        parsedLine = line.replace(imageRegex, (match, alt, src) => {
          return `
        <div style="display: flex; justify-content: center; align-items: center;">
          <img class="py-[20px]" src="${src}" alt="${alt}" style="height: auto;"/>
        </div>
      `;
        });
      } else if (videoRegex.test(line)) {
        parsedLine = line.replace(videoRegex, (match, alt, src) => {
          return `
        <div style="display: flex; justify-content: center; align-items: center;">
          <video class="py-[20px]" controls  style="max-width: 100%; height: 100%;">
            <source src="${src}" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      `;
        });
      } else if (youtubeRegex.test(line)) {
        parsedLine = line.replace(
          youtubeRegex,
          (match, alt, videoUrl, videoId) => {
            // Generate the YouTube thumbnail URL
            const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
 
            return `
<div style="position: relative; display: flex; justify-content: center; align-items: center;">
<a href="${videoUrl}" target="_blank" style="text-decoration: none;">
  <img src="${thumbnailUrl}" alt="${alt}" class="2xl:w-screen lg:w-screen  object-contain" style="max-width: 100%; height: auto; cursor: pointer;">
  <i class="fas fa-play play-icon absolute "></i>
</a>
</div>
 
<style>
/* Default (Desktop) Style */
.play-icon {
  position: absolute;
  top: 600px;
  right: 700px;
  font-size: 80px;
  color: white;
  text-align: center;
}
 
/* Tablet View */
@media (max-width: 1024px) {
  .play-icon {
    right: 10px;
    top:200px; /* Adjust for tablet */
    font-size: 50px; /* Smaller icon size for tablet */
  }
}
@media (max-width: 2000px) {
  .play-icon {
    right: 330px;
    top: 150px;
    font-size: 60px;
  }
 
 
/* Mobile View */
@media (max-width: 768px) {
  .play-icon {
    right: 170px;
    top:100px; /* Adjust for mobile */
    font-size: 40px; /* Smaller icon size for mobile */
  }
}
</style>
 
 
 
  `;
          }
        );
      } else if (customSymbolRegex2.test(line)) {
        parsedLine = line.replace(customSymbolRegex2, (match, text) => {
          // Split the text by '!!' or '::' and trim each part
          const splitParts2 = text
            .trim()
            .split(/!!|::/)
            .map((part) => part.trim())
            ?.filter((part) => part !== "");
 
          // If there are split parts, wrap them in the custom-symbol div
          if (splitParts2?.length > 0) {
            return `
          <div class="custom-symbol2 fixed-container2">
            ${splitParts2
              .map((part) => {
              
                const indentMarkers = [
                  { marker: "====", class: "indent-50" },
                  { marker: "===", class: "indent-40" },
                  { marker: "==", class: "indent-30" },
                  { marker: "=", class: "indent-20" },
                ];
 
                // Find the matching indent marker
                let indentClass = "";
                let cleanPart = part;
 
                for (const { marker, class: className } of indentMarkers) {
                  if (part.startsWith(marker)) {
                    indentClass = className;
                    cleanPart = part.slice(marker.length).trim();
                    break;
                  }
                }
 
                // Apply bold and italic replacements
                const formattedPart = cleanPart.replace(
                  boldRegex,
                  "<strong>$1</strong>"
                );
 
                return `<div class="paragraph ${indentClass}">${formattedPart}</div>`;
              })
              .join("")}
          </div>
        `;
          }
 
          // If no split parts, return the text as a single span with formatting
          const formattedText = text.replace(boldRegex, "<strong>$1</strong>");
 
          return `<span class="custom-symbol2">${formattedText}</span>`;
        });
      } else if (customSymbolRegex.test(line)) {
        parsedLine = line.replace(customSymbolRegex, (match, text) => {
          const splitParts = text
            .trim()
            .split(/!!|::/)
            .map((part) => part.trim())
            ?.filter((part) => part !== "");
 
          if (splitParts?.length > 0) {
            return `
          <div class="custom-symbol fixed-container">
            ${splitParts
              .map((part, index) => {
                const formattedPart = part.replace(
                  boldRegex,
                  "<strong>$1</strong>"
                );
 
                const marginClass = index === 0 ? "" : "mb-2";
                const additionalMarginClass = index === 0 ? "" : "ml-20";
 
                return `<div class="paragraph ${marginClass} ${additionalMarginClass}">${formattedPart}</div>`;
              })
              .join("")}
          </div>
        `;
          }
 
          const formattedText = text.replace(boldRegex, "<strong>$1</strong>");
 
          return `<span class="custom-symbol">${formattedText}</span>`;
        });
      } else if (centerRegex.test(line)) {
        parsedLine = line.replace(centerRegex, (match, content) => {
          return `<div class="text-center">${content}</div>`;
        });
      } else if (centerRegex2.test(line)) {
        parsedLine = line.replace(centerRegex2, (match, content) => {
          return `<div class="text-center italic">${content}</div>`;
        });
      } else if (centeredItalicRegex.test(line)) {
        parsedLine = line.replace(centeredItalicRegex, (match, content) => {
          return `<div class="text-center italic">${content}</div>`;
        });
      } else if (splitTextRegex.test(line)) {
        parsedLine = line.replace(
          splitTextRegex,
          (match, firstPart, secondPart) => {
            return `
        <div>
          <div>${firstPart.trim()}</div>
          <div class="py-[5px] ">${secondPart.trim()}</div>
        </div>
      `;
          }
        );
      }
      {
        parsedLine = parsedLine
          .replace(boldRegex, "<strong>$1</strong>")
         .replace(italicRegex, "<em>$1</em>")
          .replace(underlineRegex, "<u>$1</u>");
        parsedLine = `<p class="py-[10px] ">${parsedLine}</p>`;
      }
 
      parsedLine = parsedLine
        .replace(boldRegex, "<strong>$1</strong>")
        .replace(underlineRegex, "<u>$1</u>");
 
      return (
        <div
          key={`text-${sectionIndex}-${lineIndex}`}
          dangerouslySetInnerHTML={{ __html: parsedLine }}
        />
      );
    });
  });
 
  return (
    <>
      <div className="custom-markdown-container">
        <style>{`
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
    .custom-symbol {
background-color: #f3f4f6; /* gray-100 */
border-left: 4px solid #C62A6D; /* pink */
padding: 10px;
margin: 10px 0;
display: inline-block;
border-radius: 0 8px 8px 0; /* Curved right corners only */
white-space: pre-wrap;
}
 
.fixed-container {
width: 700px; /* Set a fixed width */
 
overflow-x: auto; /* Enable horizontal scrolling if content overflows horizontally */
overflow-y: hidden; /* Prevent vertical scrolling */
white-space: nowrap;
 
}
 
.custom-box {
  display: inline-block;
  background-color: #3E59B71A;
  border-radius: 0.5rem;
  padding: 16px 8px;
  margin: 0 2px;
  font-size: 0.95em;
 
}
      .custom-list {
        padding-left: 0;
        list-style-type: none;
      }
      .custom-list-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0.25em; /* Reduce the margin between list items */
        font-size: 0.875rem; /* Reduce font size for list items */
      }
      .custom-list-item .bullet {
        flex-shrink: 0;
        width: 15px; /* Reduce the width of the bullet */
        text-align: center;
        margin-right: 10px; /* Adjust spacing between bullet and text */
      }
      .custom-list-item .text {
        line-height: 1.5; /* Improve line height */
      }
      .custom-code-block {
background-color: #f5f5f5; /* Light gray background */
padding: 1rem;
border-radius: 5px;
overflow-x: auto;
}
 
.custom-symbol2 {
background-color: #f3f4f6;
border-left: 4px solid #C62A6D;
padding: 10px;
margin: 10px 0;
display: inline-block;
border-radius: 0 8px 8px 0;
white-space: pre-wrap;
}
 
.fixed-container2 {
width: 700px;
overflow-x: auto;
overflow-y: hidden;
white-space: nowrap;
}
 
.custom-symbol2 .paragraph {
padding-bottom: 5px;
min-width: 200px;
white-space: nowrap;
}
 
/* Indent levels with important flag */
.indent-20 {
margin-left: 0px !important;
}
 
.indent-30 {
margin-left: 0px !important;
}
 
.indent-40 {
margin-left:-90px !important;
}
 
.indent-50 {
margin-left: 100px !important;
}
 
@media (max-width: 1024px) {
.fixed-container2 {
  width: 500px;
}
  .fixed-container {
  width: 500px;
}
.indent-20 {
  margin-left: 0px !important;
}
 
.indent-30 {
  margin-left: 0px !important;
}
 
.indent-40 {
  margin-left: -90px !important;
}
 
.indent-50 {
  margin-left: 100px !important;
}
}
 
.blog-content pre {
  background-color: #f5f5f5 !important;
  padding: 1rem;
  border-radius: 5px;
  margin: 1rem 0;
  overflow-x: auto;
  border-left: 4px solid #C62A6D !important;
  font-family: 'Montserrat', sans-serif;
  font-display: swap;
}
 
    .blog-content code {
  font-family: 'Montserrat', monospace;
  font-display: swap;
  font-size: 0.9em;
  line-height: 1.5;
  background: none !important;
  padding: 0 !important;
}
 
      .blog-content :not(pre) > code {
        background-color: #f5f5f5;
        padding: 0.2em 0.4em;
        border-radius: 3px;
        font-size: 0.9em;
      }
 
 
          /* Add styles for code blocks */
     pre[class*="language-"] {
  border-left: 4px solid #C62A6D !important;
  background-color: #f5f5f5 !important;
  margin: 1rem 0 !important;
  width:700px;
  padding: 1rem !important;
  border-radius: 0 8px 8px 0 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-display: swap !important;
     color: #666666 !important;
}
     @media (max-width: 768px) {
  pre[class*="language-"] {
    width: 100%; /* Full width on mobile */
    padding:1rem !important; /* Optional: reduce padding for mobile */
  }
}
 
      code[class*="language-"] {
  background: none !important;
  font-family: 'Montserrat', monospace !important;
  font-display: swap !important;
  font-size: 0.9em !important;
  line-height: 1.5 !important;
   color: #666666 !important;
}
 
     
 
.custom-symbol .paragraph {
padding-bottom: 5px;
  min-width: 200px; /* Minimum width for paragraphs to ensure scrolling works */
white-space: nowrap; /* Adjust the value to set your desired gap */
}
 
 
 
@media (max-width: 768px) {
.fixed-container2 {
  width: 340px;
}
  .fixed-container {
  width:340px;
 
}
 
 
 
 
.indent-20 {
  margin-left: 0px !important;
}
 
.indent-30 {
  margin-left: 00px !important;
}
 
.indent-40 {
  margin-left: -90px !important;
}
 
.indent-50 {
  margin-left: 100px !important;
}
}
 
.mb-2 {
margin-bottom: 0px; /* Add a 10px gap between paragraphs */
}
 
.ml-20 {
margin-left: 52px; /* Additional left margin for split parts */
}
 
    `}</style>
 
        {processedSections}
      </div>
    </>
  );
};
 
export default CustomMarkdownRenderer;