import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch.js";
import homelap from "../assets/Home/homelap2.webp";
import homemob from "../assets/Home/homemobbbb.webp";
import homebg4img from "../assets/Home/homebg4img.webp";
import home6bubble from "../assets/Home/home6bubble.webp";
import homebg5img from "../assets/Home/homebg5img.webp";
import home5bubble from "../assets/Home/home5bubble.webp";
import homebg6img from "../assets/Home/homebg6img.webp";
import rightsideimg from "../assets/Home/rightsideimg.webp";
import leftsideimg from "../assets/Home/leftsideimg.webp";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import homeflowmobile2 from "../assets/Home/mobile.webp";
import { Helmet } from "react-helmet-async";

import homeflow from "../assets/Home/homeflow.webp";
import home2 from "../assets/Home/home2flow2.webp";
import arrow from "../assets/Home/arrow.webp";
import pinkarrow from "../assets/Home/pinkarrow.webp";
import grayarrow from "../assets/Home/grayarrow.webp";
import yelloww1 from "../assets/Home/yellow1.webp";
import yelloww2 from "../assets/Home/yellow2.webp";
import yelloww3 from "../assets/Home/yellow3.webp";
import yelloww4 from "../assets/Home/yellow4.webp";

import Lottie from "react-lottie";
import loading from "../pages/loading.json";

import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";
import yelloww5 from "../assets/Home/yellow5.webp";
import { Client, Databases, ID } from "appwrite";

// import home from "../assets/Home/home.gif";

import "react-phone-input-2/lib/style.css";

import Popup from "../components/popup.js";
function titleToSlug(title) {
  return title
    .toLowerCase()
    .replace(/[^\w\s-]/g, "") 
    .replace(/[\s_-]+/g, "-") 
    .replace(/^-+|-+$/g, ""); 
}
const PartnerPopup = ({ onClose }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <p>Thank you for your interest in becoming a partner!</p>
      </div>
    </div>
  );
};

const Home = ({utmContent}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadinganimation, setIsLoadinganimation] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const [isSuccessOpenintegration, setIsSuccessOpenintegration] =
    useState(false);
  const [productname, setproductname] = useState("");
  const [businessurl, setbusinessurl] = useState("");
  const [name, setname] = useState("");
  const [aiproduct, setaiproduct] = useState("");
  const [companyname, setcompanyname] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [businessemail, setbusinessemail] = useState("");
 
  const [utmParams, setUtmParams] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_id: "",
    utm_term: "",
    utm_content: "",
  });

  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setproductname("");
    setbusinessurl("");
    setname("");
    setaiproduct("");
    setcompanyname("");
    setphonenumber("");
    setbusinessemail("");
    setIsChecked(true);
    setErrors({});
  };
  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const client = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (number, country) => {
    setphonenumber(number);

    try {

      const fullNumber = number.startsWith("+") ? number : `+${number}`;


      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;

        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;

        if (actualLength < expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phonenumber: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phonenumber: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {

          setErrors((prev) => ({
            ...prev,
            phonenumber: "",
          }));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          phonenumber: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrors((prev) => ({
        ...prev,
        phonenumber: "Please enter a valid phone number",
      }));
    }
  };
  
  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "preload";
    link.as = "image";
    link.href = homemob; 
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);


  useEffect(() => {
    // Set page title
    document.title = "Random Walk | Advanced AI Solutions for Modern Enterprises";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Start your AI journey with Random Walk AI—your expert in cutting-edge AI solutions, AI training, and AI integration services. Empower your business with tailored strategies and AI services.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Start your AI journey with Random Walk AI—your expert in cutting-edge AI solutions, AI training, and AI integration services. Empower your business with tailored strategies and AI services.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', "Random Walk | Advanced AI Solutions for Modern Enterprises");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Start your AI journey with Random Walk AI—your expert in cutting-edge AI solutions, AI training, and AI integration services. Empower your business with tailored strategies and AI services.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/";
  }, []);



  useEffect(() => {
    const captureUtmParams = () => {
      const params = new URLSearchParams(window.location.search);
      const utmFields = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_id",
        "utm_term",
        "utm_content",
      ];
  
      const newUtmParams = {      utm_content: utmContent,};
      utmFields.forEach((field) => {
        newUtmParams[field] = params.get(field) || "";
      });
      setUtmParams(newUtmParams);
    };
  
    captureUtmParams();
    window.addEventListener("popstate", captureUtmParams);
  
    return () => {
      window.removeEventListener("popstate", captureUtmParams);
    };
  }, []);



  const handleSubmitcontact = async (event) => {
    event.preventDefault();
    setIsLoadinganimation(true); 
    
    try {
      
    if (!phonenumber) {
      setErrors({ phoneNumber: "Phone number is required" });
      setIsLoadinganimation(false);
      return;
    }
      const fullNumber = phonenumber.startsWith("+")
        ? phonenumber
        : `+${phonenumber}`;
        
      if (!isValidPhoneNumber(fullNumber)) {
        setErrors((prev) => ({
          ...prev,
          phonenumber: "Please enter a valid phone number",
        }));
        return;
      }
      const formData = {
        name,
        productname,
        businessurl,
        aiproduct,
        companyname,
        phonenumber: fullNumber,
        businessemail,
        utmSource: utmParams.utm_source,
        utmMedium: utmParams.utm_medium,
        utmCampaign: utmParams.utm_campaign,
        utmId: utmParams.utm_id,
        utmTerm: utmParams.utm_term,
        utmContent: "homepage", 
      };
  await Promise.all ([
    databases.createDocument(
    process.env.REACT_APP_DATABASE_ID,
    process.env.REACT_APP_COLLECTION_ID5,
    ID.unique(),
  formData
  ),

  
fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-partner`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(formData),
}).then(async(response) => {

if (!response.ok) {
  const errorData = await response.json();
  throw new Error(errorData.message || 'Network response was not ok');
}
})
  ]);
  setShowPopup(false);
      setIsSuccessOpen(true);
    } catch (error) {
      console.error("Error submitting partner form:", error);
    }
  };
  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
    resetForm();
  };

  
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [isIntegrationLoading, setIsIntegrationLoading] = useState(false);
  const [companynameint, setcompanynameint] = useState("");
  const [phonenumberint, setphonenumberint] = useState("");
  const [emailid, setemailid] = useState("");

  const [utmParamsintegration, setUtmParamsintegration] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_id: "",
    utm_term: "",
    utm_content: "",
  });


  const [errorsintegration, setErrorsintegration] = useState({});

  const resetForms = () => {
    setfirstname("");
    setlastname("");
    setcompanynameint("");
    setphonenumberint("");
    setemailid("");
    setIsChecked(true);
    setErrorsintegration({});
  };
  const handleButtonClickintegration = () => {
    setIsModalOpen(true);
  };

  const closeModalintegration = () => {
    setIsModalOpen(false);
    resetForms();
  };

  const clientintegration = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databasesintegration = new Databases(client);

  const handlePhoneNumberChangeintegration = (number, country) => {
    setphonenumberint(number);

    try {

      const fullNumber = number.startsWith("+") ? number : `+${number}`;


      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;

 
        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;

        if (actualLength < expectedLength) {
          setErrorsintegration((prev) => ({
            ...prev,
            phonenumberint: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrorsintegration((prev) => ({
            ...prev,
            phonenumberint: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {
  
          setErrorsintegration((prev) => ({
            ...prev,
            phonenumberint: "",
          }));
        }
      } else {
        setErrorsintegration((prev) => ({
          ...prev,
          phonenumberint: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrorsintegration((prev) => ({
        ...prev,
        phonenumberint: "Please enter a valid phone number",
      }));
    }
  };

 
  

  useEffect(() => {
    const captureUtmParams = () => {
      const params = new URLSearchParams(window.location.search);
      const utmFields = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_id",
        "utm_term",
        "utm_content",
      ];
  
      const newUtmParams = {      utm_content: utmContent,};
      utmFields.forEach((field) => {
        newUtmParams[field] = params.get(field) || "";
      });
      setUtmParamsintegration(newUtmParams);
    };
  
    captureUtmParams();
    window.addEventListener("popstate", captureUtmParams);
  
    return () => {
      window.removeEventListener("popstate", captureUtmParams);
    };
  }, []);

  const handleSubmitintegration = async (event) => {
    event.preventDefault();
    setIsIntegrationLoading(true);
    
    try {
      const fullNumber = phonenumberint.startsWith("+")
        ? phonenumberint
        : `+${phonenumberint}`;
      
      if (!isValidPhoneNumber(fullNumber)) {
        setErrorsintegration((prev) => ({
          ...prev,
          phonenumberint: "Please enter a valid phone number",
        }));
        return;
      }
  const formData={
    firstname,
          lastname,
          companynameint,
          phonenumberint,
          emailid,
          // utmSourceintegration,
          // utmMediumintegration,
          // utmCampaignintegration,
          // utmIdintegration,
          // utmTermintegration,
          // utmContentintegration,
          utmSourceintegration: utmParamsintegration.utm_source,
          utmMediumintegration: utmParamsintegration.utm_medium,
          utmCampaignintegration: utmParamsintegration.utm_campaign,
          utmIdintegration: utmParamsintegration.utm_id,
          utmTermintegration: utmParamsintegration.utm_term,
          utmContentintegration: "homepage", 
          
  }
    
  
      await Promise.all([
        databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID6,
        ID.unique(),
        formData
        
      ),
  
   fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-integration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      }).then(async(response)=>{
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit form');
      }
    })
  ]);
  
      setShowPopup(false);
      setIsSuccessOpenintegration(true);
      resetForms();
    }catch (error) {
      console.error("Error submitting form:", error);
      setErrors({ submit: "Failed to submit form. Please try again." });
    } finally {
      setIsLoadinganimation(false);
    }
  };
  const closeSuccessModalintegration = () => {
    setIsSuccessOpenintegration(false);
  };

  // const [blogs, setBlogs] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");

  const togglePopup = (type) => {
    setShowPopup(!showPopup);
    setPopupType(type);
  };

  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageHeight(img.height);
    };
    img.src = homeflow;
  }, []);

  const [showExtraContent, setShowExtraContent] = useState(false);
  const toggleExtraContent = () => {
    setShowExtraContent(!showExtraContent);
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };

  const toggleDropdown3 = () => {
    setIsOpen3(!isOpen3);
  };

  const toggleDropdown4 = () => {
    setIsOpen4(!isOpen4);
  };

  const toggleDropdown5 = () => {
    setIsOpen5(!isOpen5);
  };

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const [blogs, setBlogs] = useState(null);



  useEffect(() => {
    setIsLoading(true); // Add this line
    fetch(
      "https://devrw.blob.core.windows.net/rw-website/strapi-data/blog/top_5.json"
    )
      .then((response) => response.json())
      .then((data) => {
        const validatedData = data
          .map((blog) => ({
            ...blog,
            effectiveDate: new Date(
              blog.blogDate || blog.publishedAt
            ).getTime(),
          }))
          .filter((blog) => !isNaN(blog.effectiveDate));

        const sortedBlogs = validatedData.sort(
          (a, b) => b.effectiveDate - a.effectiveDate
        );

        setBlogs(sortedBlogs);
        setIsLoading(false); // Add this line
      })
      .catch((error) => {
        console.error("Error fetching blogs:", error);
        setBlogs([]);
        setIsLoading(false); // Add this line
      });
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString(undefined, options);
  };

  const sortedBlogs = [...(blogs || [])].sort((a, b) => {
    // Convert IDs to numbers and sort in descending order
    return (b?.id || 0) - (a?.id || 0);
  });

  if (isLoading) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }

  if (!blogs) return null;
  

  return (
    <>
 <Helmet>
    <link rel="preload" as="image" href={homemob} />
  </Helmet>

      <div className="body font-display cus4:container cus4:mx-auto ">
        {showPopup && (
          <div className="fixed font-display top-0 left-0 w-full h-full flex justify-center items-center z-50">
            <div className="bg-black bg-opacity-50 absolute inset-0"></div>

            {popupType === "integration" && (
              <div className="fixed font-display top-0 left-0 w-full h-full flex justify-center items-center z-50">
                <div className="bg-black bg-opacity-50 absolute inset-0"></div>
                <div className="bg-white p-5 w-full max-w-lg rounded-2xl shadow-lg relative flex flex-col lg:flex-row mx-2">
                  {popupType === "integration" && (
                    <div className="flex-1">
                      <a
                        onClick={() => togglePopup("")}
                        className="absolute top-2 right-2 z-10 text-gray-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 cursor-pointer"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </a>

                      <p className="font-display font-bold mb-3 text-lg text-center">
                        {" "}
                        Integration Request
                      </p>
                      <div className="border border-gray-300 mb-3 w-full"></div>

                      <form
                        className="flex flex-col items-center"
                        onSubmit={handleSubmitintegration}
                      >
                        <div className="max-h-[400px] overflow-y-auto custom-scrollbar pr-4 ">
                          <div className="mb-4 w-full">
                            <label
                              htmlFor="firstName"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              id="FirstNameint"
                              required
                              name="firstName"
                              value={firstname}
                              onChange={(e) => setfirstname(e.target.value)}
                              className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              placeholder="Enter First Name"
                            />
                          </div>
                          <div className="mb-4 w-full">
                            <label
                              htmlFor="lastName"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              id="lastnameint"
                              required
                              name="lastName"
                              value={lastname}
                              onChange={(e) => setlastname(e.target.value)}
                              className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              placeholder="Enter Last Name"
                            />
                          </div>
                          <div className="mb-4 w-full">
                            <label
                              htmlFor="phoneNumber"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Phone Number
                            </label>
                            <PhoneInput
                              country={"in"}
                              type="tel"
                              id="Phonenumberint"
                              required
                              name="phonenumberint"
                              value={phonenumberint}
                              onChange={(number, country) =>
                                handlePhoneNumberChangeintegration(
                                  number,
                                  country
                                )
                              }
                              inputClass="custom-phone-input4"
                              buttonClass="border rounded"
                              dropdownClass="border rounded"
                              placeholder="Enter Phone Number"
                              enableSearch={true}
                              inputProps={{
                                required: true,
                                autoFocus: false,
                              }}
                            />
                            {errorsintegration.phonenumberint && (
                              <p className="text-red-500 text-sm">
                                {errorsintegration.phonenumberint}
                              </p>
                            )}
                          </div>
                          <div className="mb-4 w-full">
                            <label
                              htmlFor="companyName"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Company Name
                            </label>
                            <input
                              type="text"
                              id="Companynameint"
                            
                              required
                              name="companynameint"
                              value={companynameint}
                              onChange={(e) =>
                                setcompanynameint(e.target.value)
                              }
                              className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              placeholder="Enter Company Name"
                            />
                          </div>
                          <div className="mb-4 w-full">
                            <label className="block text-gray-700 text-sm mb-2">
                              Business Email
                            </label>
                            <input
                              type="email"
                              id="businessemailint"
                              required
                              name="emailid"
                              value={emailid}
                              onChange={(e) => setemailid(e.target.value)}
                              className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              placeholder="Enter Email Address"
                            />
                          </div>
                          <div className="disclaimerContainer2">
                            <label className="checkboxLabel">
                              <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                className="checkbox"
                              />
                              <span className="checkboxText">
                                I agree and authorize team to contact me and/or
                                send relevant information over Email, SMS &
                                WhatsApp. This will override the registry with
                                DNC/NDNC.
                              </span>
                            </label>
                          </div>
                          {errors.terms && (
                            <p className="text-red-500 text-sm">
                              {errors.terms}
                            </p>
                          )}
                          <div className="linksContainer">
                            <a href="/terms-of-service/" className="link">
                              Terms of Service
                            </a>
                            <a href="/privacy-policy/" className="link">
                              Privacy Policy
                            </a>
                          </div>
                        </div>
                        <button
  type="submit"
  id="submitint"
  className={`bg-gray-500 text-white px-20 py-2 rounded hover:bg-blue-600 w-full max-w-xs text-center mt-3 ${
    !isChecked || isIntegrationLoading ? "opacity-50 cursor-not-allowed" : ""
  }`}
  disabled={!isChecked || isIntegrationLoading}
>
  {isIntegrationLoading ? (
    <div className="flex items-center justify-center space-x-2">
      <svg
        className="animate-spin h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      <span>Sending...</span>
    </div>
  ) : (
    "Send"
  )}
</button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            )}

            {popupType === "partner" && (
              <div className="fixed inset-0 flex  font-display items-center justify-center bg-gray-800 bg-opacity-50 p-4 lg:px-40 lg:py-10 ">
                <div className="bg-white border border-gray-300 rounded-lg flex-1 flex flex-col shadow-md p-4  relative w-full lg:w-[80%] lg:h-auto overflow-y-auto lg:overflow-block max-h-[100%]">
                  <button
                    onClick={togglePopup}
                    className="absolute top-2 right-2 z-10 text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <h2 className="text-xl text-center font-bold mb-4">
                    Become a Partner
                  </h2>
                  <div className="border border-gray-300 mb-3 w-full"></div>

                  <div className="flex flex-col lg:flex-row">
                    <div className="lg:w-1/2 flex flex-col justify-center items-center mb-4 lg:mb-0">
                      <div className="flex flex-row items-center mb-4">
                        <img
                          src={yelloww1}
                          alt="Image 1"
                          className="w-12 h-12 mr-2"
                        />
                        <div className="lg:mr-5 lg:ml-4">
                          <div className="text-lg font-bold mb-2">
                            Seamless Integration
                          </div>
                          <div className="text-sm">
                            Eliminate the complexity and technical challenges
                            associated with integration and focus more on your
                            tool's functionalities.
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-row items-center mb-4">
                        <img
                          src={yelloww2}
                          alt="Image 2"
                          className="w-12 h-12 mr-2"
                        />
                        <div className="lg:mr-5 lg:ml-4">
                          <div className="text-lg font-bold mb-2">
                            Expanded Market Reach
                          </div>
                          <div className="text-sm">
                            We actively promote and market your tools through
                            our channels including targeted marketing campaigns,
                            events and industry partnerships.
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center mb-4">
                        <img
                          src={yelloww3}
                          alt="Image 3"
                          className="w-12 h-12 mr-2"
                        />
                        <div className="lg:mr-5 lg:ml-4">
                          <div className="text-lg font-bold mb-2">
                            Business Development Support
                          </div>
                          <div className="text-sm">
                            Discover a collaborative environment where
                            complementary tools and technologies can be
                            integrated.
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center mb-4">
                        <img
                          src={yelloww4}
                          alt="Image 4"
                          className="w-12 h-12 mr-2"
                        />
                        <div className="lg:mr-5 lg:ml-4">
                          <div className="text-lg font-bold mb-2">
                            Synergistic Collaboration
                          </div>
                          <div className="text-sm">
                            Eliminate the complexity and technical challenges
                            associated with integration and focus more on your
                            tool's functionalities.
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center mb-4">
                        <img
                          src={yelloww5}
                          alt="Image 5"
                          className="w-12 h-12 mr-2"
                        />
                        <div className="lg:mr-5 lg:ml-4">
                          <div className="text-lg font-bold mb-2">
                            Increased Brand Exposure
                          </div>
                          <div className="text-sm">
                            Random Walk’s platform allows tools to gain exposure
                            to a wider audience, increasing brand recognition.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="lg:w-1/2 flex justify-center items-center">
                      <form
                        className="w-full lg:w-3/4"
                        onSubmit={handleSubmitcontact}
                      >
                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm mb-2">
                            Product Name
                          </label>
                          <input
                            type="text"
                            id="productname"
                            required
                            name="productname"
                            value={productname}
                            onChange={(e) => setproductname(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter Product Name"
                          />
                        </div>

                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm mb-2">
                            Business URL
                          </label>
                          <input
                            type="url"
                            id="businessurl"
                            required
                            name="businessurl"
                            value={businessurl}
                            onChange={(e) => setbusinessurl(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="https://example.com"
                          />
                        </div>

                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm mb-2">
                            About AI Product
                          </label>
                          <input
                            type="text"
                            id="aboutaipproduct"
                            required
                            name="aiproduct"
                            value={aiproduct}
                            onChange={(e) => setaiproduct(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter about AI product"
                          />
                        </div>

                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm mb-2">
                            Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            required
                            name="name"
                            value={name}
                            onChange={(e) => setname(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter your name"
                          />
                        </div>

                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm mb-2">
                            Company Name
                          </label>
                          <input
                            type="text"
                            id="companyname"
                            required
                            name="companyname"
                            value={companyname}
                            onChange={(e) => setcompanyname(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter Company Name"
                          />
                        </div>

                        <div className="mb-4">
                          <label className="block text-gray-700 text-sm mb-2">
                            Business Email
                          </label>
                          <input
                            type="email"
                            id="businessemail"
                            required
                            name="businessemail"
                            value={businessemail}
                            onChange={(e) => setbusinessemail(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter Business Email"
                          />
                        </div>
                        <div className="mb-4 w-full">
                          <label
                            htmlFor="phoneNumber"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            country={"in"}
                            type="tel"
                            id="Phonenumber"
                            required
                            name="phonenumber"
                            value={phonenumber}
                            onChange={(number, country) =>
                              handlePhoneNumberChange(number, country)
                            }
                            inputClass="custom-phone-input4"
                            buttonClass="border rounded"
                            dropdownClass="border rounded"
                            placeholder="Enter Phone Number"
                            enableSearch={true}
                            inputProps={{
                              required: true,
                              autoFocus: false,
                            }}
                          />
                          {errors.phonenumber && (
                            <p className="text-red-500 text-sm">
                              {errors.phonenumber}
                            </p>
                          )}
                        </div>

                        <div className="disclaimerContainer2">
                          <label className="checkboxLabel">
                            <input
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                              className="checkbox"
                            />
                            <span className="checkboxText">
                              I agree and authorize team to contact me and/or
                              send relevant information over Email, SMS &
                              WhatsApp. This will override the registry with
                              DNC/NDNC.
                            </span>
                          </label>
                        </div>

                        {errors.terms && (
                          <p className="text-red-500 text-sm">{errors.terms}</p>
                        )}
                        <div className="linksContainer mr-16 md:mr-0">
                          <a href="/terms-of-service/" className="link">
                            Terms of Service
                          </a>
                          <a href="/privacy-policy/" className="link">
                            Privacy Policy
                          </a>
                        </div>
                        <div className="mb-4">
  <button
    id="submit"
    type="submit"
    className={`w-full px-4 py-2 bg-[#C62B6D] text-white rounded ${
      !isChecked || isLoadinganimation ? "opacity-50 cursor-not-allowed" : ""
    }`}
    disabled={!isChecked || isLoadinganimation}
  >
    {isLoadinganimation ? (
      <div className="flex items-center justify-center space-x-2">
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        <span>Submitting...</span>
      </div>
    ) : (
      "Submit"
    )}
  </button>
</div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

    

        <section className="font-display bg-[#3E59B7] md:py-12 lg:py-16 flex flex-col md:flex-row justify-start items-center 2xl:justify-center px-12 md:px-0">
          <article className="flex items-center mt-24 md:mt-10 lg:mt-0 justify-center text-left md:px-10 lg:px-16 md:text-left">
            <div className="text-white">
              <h2 className="text-xl 2xl:hidden text-left md:text-2xl md:mt-5 lg:text-4xl 2xl:text-6xl font-bold md:mb-6 mb-3">
                Maximize Your Business Potential With Seamless AI Integration.
              </h2>
              <h2 className="text-xl max-2xl:hidden text-left md:text-2xl md:mt-5 lg:text-4xl 2xl:text-6xl font-bold md:mb-6 mb-3">
                Maximize Your Business <br/>Potential With Seamless AI Integration.
              </h2>
              <p className="md:text-xs text-xs lg:text-lg 2xl:text-xl font-medium">
              With our AI consulting and AI integration services, you now have the ability to choose from an entire gamut of AI solutions and tools to deliver quick results.
              
              </p>
            </div>
          </article>



          <figure className="max-md:hidden md:mt-12 md:mx-16 lg:mx-16">
            <picture>
  
              <source
                srcSet="/static/media/homelap2.67249afaa736c427c3c2.webp"
                type="image/webp"
                sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
              />
         
              <source
                srcSet="/static/media/homelap2.67249afaa736c427c3c2.jpg"
                type="image/jpeg"
                sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
              />

              <img
                src="/static/media/homelap2.67249afaa736c427c3c2.webp"
                alt="RandomWalk"
                className="md:w-[500px] md:h-[200px] lg:w-[1050px] lg:h-[450px] 2xl:w-[750px] 2xl:h-[620px]"
                width="850"
                height="500"
              />
            </picture>
          </figure>

          <figure className="block md:hidden pb-4 md:p-3">
  <img
    src={homemob}
    alt="RandomWalk"
    className="w-[300px] h-[300px] max-w-full"
    width="300"
    height="300"
    loading="eager"
    fetchpriority="high"
  />
</figure>


        </section>

        
          <h2 className="font-bold md:hidden text-lg pt-5 md:text-xl pb-3 lg:text-4xl 2xl:text-4xl text-[#666666] px-12">
            Leading The World’s AI Integration
          </h2>
          <section className="md:hidden block text-center md:text-left font-display overflow-x-scroll scrollbar-hide mt-5">
          <figure
            className="inline-block"
            style={{ width: "800px", height: "400px" }} // Ensure the figure has fixed dimensions
          >
            <img
            className="w-[800px] h-[400px] " 
              src={homeflowmobile2}
              alt="Leading_the_World_Ai_integration"
           
            />
          </figure>
        </section>

        <section className="text-center max-md:hidden md:text-left font-display">
          <h2 className="font-bold text-xl md:text-xl lg:text-4xl 2xl:text-4xl text-[#666666] pt-10 md:pt-12 2xl:mt-5 md:px-12 lg:px-24 2xl:px-40">
            Leading The World’s AI Integration
          </h2>

          <div className="flex flex-col md:flex-row justify-center md:justify-between mt-5 lg:mt-8 px-4 md:px-10 lg:px-20 2xl:px-36">
            <p className="flex-1 text-base md:text-xs lg:text-base 2xl:text-lg lg:text-md text-[#666666] px-2 lg:px-4">
              Choose the ideal AI tools for your business with expert guidance
              from Random Walk, where we collaborate to implement custom AI
              solutions.
            </p>
            <p className="flex-1 px-2 text-base md:text-xs lg:text-base 2xl:text-lg lg:text-md text-[#666666] lg:px-4 mt-4 md:mt-0">
              Our engineers specialize in seamless AI integration, accelerating
              connections between AI tools and existing software.
            </p>
            <p className="flex-1 px-2 lg:px-4 text-base lg:text-base 2xl:text-lg md:text-xs lg:text-md text-[#666666] mt-4 md:mt-0">
              From business functions like marketing, HR and finance to
              different industries like retail and pharma, find the right AI
              tool.
            </p>
          </div>


          <figure className="md:-mt-5 lg:-mt-10 lg:px-5 2xl:px-16">
            <picture>


              <source
                srcSet="/static/media/home2flow2.64bd40386203028a86c9.webp"
                type="image/webp"
                sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
              />


              <source
                srcSet="/static/media/home2flow2.64bd40386203028a86c9.jpg"
                type="image/jpeg"
                sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
              />
             

              <img
                src="/static/media/home2flow2.64bd40386203028a86c9.webp"
                alt="Leading_the_World_Ai_integration"
                className="md:w-[1000px] md:h-[400px] lg:w-[1200px] lg:h-[600px] 2xl:w-[1700px] 2xl:h-[850px]"
                loading="lazy"
                width="1700"
                height="850"
              />
            </picture>
          </figure>
        </section>

        <section className="hidden  md:-mt-10 lg:-mt-20  md:block">
          <div className="  bg-[#EBF7FF] 2xl:-mt-0 md:pb-80  2xl:pb-72 h-max bg-cover bg-no-repeat ">
            <section className="md:flex   2xl:pt-44 2xl:pb-72 md:mb-10 lg:pb-20 flex-row md:flex-row md:pt-10 lg:pt-28  md:gap-10 lg:gap-28 2xl:gap-52 md:px-24 md:justify-center md:items-center md:max-w-full ">
              <aside className="md:bg-white md:p-6 md:rounded-2xl md:px-8 md:w-[100%] md:h-[400px] lg:h-auto lg:scale-110 2xl:w-[500px] 2xl:h-[350px]">
                <div>
                  <p className="md:text-sm text-[#666666] 2xl:text-base lg:text-base md:font-display md:pb-4 md:pt-7">
                    If you are an AI tools provider,
                  </p>
                  <h3 className="lg:text-2xl text-[#C62B6D] md:text-sm md:font-semibold md:pb-5 md:font-display">
                    Make It Easy To Choose <br /> Your Tools
                  </h3>
                  <p className="md:text-sm 2xl:text-base text-[#666666] md:font-display md:pb-5 2xl:pb-5">
                    Work with Random Walk to integrate your AI tool into our
                    platform. While our customers develop software with us, we
                    will recommend your AI solution.
                  </p>
                </div>
                <button
                  onClick={() => togglePopup("partner")}
                  className="md:hover:bg-[#C62B6D] md:hover:text-white md:hover:border-[#C62B6D] md:border-[#C62B6D] md:mt-3 md:mb-3 md:font-display md:bg-[#C62B6D] md:text-white md:font-bold md:py-2 md:px-5 md:rounded md:text-xs lg:text-sm"
                >
                  Become a Partner
                </button>
              </aside>

              <article className="md:bg-white md:p-6 md:rounded-2xl lg:ml-4 md:px-8 md:w-[100%] lg:w-[100%] lg:max-w-[1200px] md:h-[400px] lg:h-auto lg:scale-110 2xl:w-[500px] 2xl:h-[350px]">
                <div className="text-[#666666]">
                  <p className="md:text-sm text-[#666666] 2xl:text-base lg:text-base md:font-display md:pt-7">
                    If you are a client looking for AI capabilities,
                  </p>
                  <h3 className="lg:text-2xl  text-[#C62B6D] md:text-sm md:pb-5 md:font-semibold md:font-display md:pt-4">
                    Begin Your AI Journey <br /> Now
                  </h3>
                  <p className="md:text-sm 2xl:text-base text-[#666666] md:font-display md:pb-1">
                    Let us work with you to integrate niche AI tools into your
                    day-to-day business functions like HR, marketing, and
                    finance. We also cater to various industry-specific AI tools
                    to aid operations.
                  </p>
                </div>
                <button
                  onClick={() => togglePopup("integration")}
                  className="md:hover:bg-[#C62B6D] md:text-xs md:hover:text-white md:hover:border-[#C62B6D] md:border-[#C62B6D] md:mt-3 md:mb-3 md:font-display md:bg-[#C62B6D] md:text-white md:font-bold md:py-2 md:px-5 md:rounded lg:text-sm"
                >
                  Create Integration Request
                </button>
              </article>
            </section>
          </div>
          <div className="relative w-full h-0 -translate-y-full">
            <div className="triangle3 scale-x-100"></div>
          </div>
          <div className="relative w-full h-0 -translate-y-full">
            <div className="triangle4  scale-x-100"></div>
          </div>

          <section className="  bg-[#FFEBF4]   h-max bg-cover bg-no-repeat mt-2 ">
            <div className="absolute flex   2xl:-translate-y-12 flex-wrap items-center justify-center relative md:-top-64 lg:-top-72 ">
              <div className="lg:ml-20 flex  items-center relative  ">
                <figure className=" lg:mr-20 md:px-12 2xl:translate-x-32 lg:w-auto 2xl:w-7/12 2xl:h-7/12 md:w-8/12 lg:-mt-20 2xl:-mt-10 ">
                  <img src={homebg4img} alt="AI_Integration" className="" />
                </figure>

                <img
                  src={home6bubble}
                  alt="AI_Integration_img1"
                  className="absolute md:w-5/12 lg:w-6/12  2xl:w-4/12 md:top-32 md:right-0 lg:top-40 lg:right-0"
                />

                <div className="flex bg-white opacity-75 border-2 2xl:ml-80  border-white flex-col 2xl:w-full md:w-full z-40 bg-cover rounded-xl bg-no-repeat relative">
                  <a href="/ai-integration/" className="block">
                    <figure className="absolute top-0 right-0 ml-6  lg:ml-0 md:w-16 lg:w-28 lg:-mt-16 h-auto">
                      <img src={rightsideimg} alt="AI_Integration_img2" />
                    </figure>

                    <article className="flex flex-col lg:pl-24 md:p-10 lg:py-16">
                      <h2 className="text-[#565656] font-display md:text-xl lg:text-3xl  2xl:text-4xl font-semibold md:pb-3 lg:pb-5 text-left">
                        AI Integration
                      </h2>
                      <p className="text-[#000000] text-xs z-50 font-display md:text-xs  2xl:text-lg lg:text-base md:max-w-sm 2xl:max-w-2xl md:pb-7 md:text-left">
                        With our extensive experience, we’ve perfected the skill
                        of managing different AI tools and software. Our
                        expertise lies in seamless AI integration, including
                        ChatGPT integration & more, into various systems with
                        precision. Trust us for a smooth AI integration
                        services, which starts with AI consulting services that
                        ensures flawless implementation and deliver evident
                        results.
                      </p>
                    </article>
                    <div className="absolute right-0 -bottom-5 m-4 group">
                      <div className="relative w-10 h-10 md:w-20 md:h-20  lg:w-32 lg:h-32 overflow-hidden">
                        <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                          <img
                            src={grayarrow}
                            alt="Gray Arrow"
                            className="w-10 h-10 -mr-3"
                          />
                        </div>

                        <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                          <img
                            src={pinkarrow}
                            alt="Pink Arrow"
                            className="w-10 h-10 -mr-3"
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className=" absolute relative text-grayy    pt-32   2xl:translate-y-[0px]  bg-no-repeat bg-cover md:-top-48 lg:-top-52 2xl:ml-40 ">
              <div className="flex flex-wrap items-center justify-center">
                <figure className="flex items-center ">
                  <img
                    src={home5bubble}
                    alt="Learn_Corporate_AI_Fundamentals_img1"
                    className="absolute  md:w-6/12 lg:w-5/12 2xl:w-5/12  md:-top-0 md:left-0 lg:-top-12  2xl:-top-32 lg:left-0"
                  />

                  <article className="relative flex bg-white 2xl:-translate-x-40 opacity-60 z-40 flex-col w-full rounded-xl border-white border-b border-t">
                    <figure className="absolute left-0 top-0 md:w-24 lg:w-40 2xl:w-44 -mt-5 h-auto max-md:hidden">
                      <img
                        src={leftsideimg}
                        alt="Learn_Corporate_AI_Fundamentals_img2"
                        className=""
                      />
                    </figure>

                    <div className="flex flex-col lg:pl-40 2xl:pl-40 md:p-10 lg:py-16">
                      <a href="/ai-workshop-for-corporates/" className="block">
                        <h2 className="font-display md:text-xl  text-[#565656] 2xl:text-4xl lg:text-3xl font-semibold md:pb-3 lg:pb-5 text-left">
                          Learn Corporate AI <br /> Fundamentals
                        </h2>
                        <p className="text-xs font-display md:text-xs text-[#000000] z-50 2xl:text-lg lg:text-base md:max-w-sm 2xl:max-w-2xl md:pb-7 md:text-left">
                          Random Walk’s AI learning endeavors to unravel the
                          mysteries surrounding AI, ensuring it’s not a black
                          box. We offer enhanced learning through
                          industry-oriented sessions, employing a seamless mix
                          of learning models. Explore our AI workshops and
                          corporate AI training to empower your employees with
                          valuable skills.
                          <br />
                          <br /> Our AI Training and AI consulting services for
                          executives ensures a straightforward and effective
                          learning experience.
                        </p>

                        <div className="absolute md:bottom-0 md:right-0 lg:bottom-0 lg:right-24 2xl:right-5 m-4 group">
                          <div className="relative  md:w-16 md:h-16 lg:w-32 lg:h-32   overflow-hidden">
                            <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                              <img
                                src={grayarrow}
                                alt="Gray Arrow"
                                className="w-10 h-10 -mr-3"
                              />
                            </div>

                            <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                              <img
                                src={pinkarrow}
                                alt="Pink Arrow"
                                className="w-10 h-10 -mr-3"
                              />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </article>
                  <div className=" lg:px-20 lg:w-auto 2xl:w-9/12 md:w-9/12 md:px-12 ">
                    <img
                      src={homebg5img}
                      alt="Learn_Corporate_AI_Fundamentals"
                      className="lg:w-auto 2xl:w-9/12 md:w-11/12 md:ml-5 "
                    />
                  </div>
                </figure>
              </div>
            </div>
          </section>

          <div className="relative w-full h-0 -translate-y-full">
            <div className="triangle3 scale-x-100"></div>
          </div>
          <div className="relative w-full h-0 -translate-y-full ">
            <div className="triangle5  scale-x-100"></div>
          </div>

          <section className="  bg-[#EFECFF]  lg:pb-56 2xl:pb-40 h-max bg-cover bg-no-repeat mt-2 ">
            <div className="text-grayy   md:translate-y-60 lg:translate-y-0    bg-no-repeat bg-cover pb-20">
              <article className="absolute flex lg:translate-y-96 opacity-80 2xl:translate-y-96 flex-wrap items-center justify-center relative md:-top-64 lg:-top-72 ">
                <div className="lg:ml-20 flex  items-center relative  ">
                  <figure className="lg:w-auto lg:mr-20 md:px-12 2xl:translate-x-32 2xl:w-7/12 lg:-mt-20 2xl:-mt-10 md:w-8/12">
                    <img src={homebg6img} alt="AI_Integration_img" />
                  </figure>

                  <img
                    src={home6bubble}
                    alt="AI_Integration_img1"
                    className="absolute md:w-5/12 lg:w-5/12  2xl:w-4/12 md:top-32 md:right-0 lg:top-40 lg:right-0"
                  />

                  <div className="flex bg-white opacity-75 border-2 2xl:ml-80  border-white flex-col 2xl:w-full md:w-full z-40 bg-cover rounded-xl bg-no-repeat relative">
                    <a href="/ai-managed-services/" className="block">
                      <figure className="absolute top-0 right-0 ml-6  lg:ml-0 md:w-16 lg:w-28 lg:-mt-16 h-auto">
                        <img src={rightsideimg} alt="AI_Integration_img2" />
                      </figure>

                      <div className="flex flex-col lg:pl-24 md:p-10 lg:py-16 ">
                        <h2 className="text-[#565656] font-display md:text-xl lg:text-3xl  2xl:text-4xl font-semibold md:pb-3 lg:pb-5 text-left">
                          AI Managed Services
                        </h2>
                        <p className="text-[#000000] text-xs z-50 font-display md:text-xs  2xl:text-lg lg:text-base md:max-w-sm 2xl:max-w-2xl md:pb-7 md:text-left">
                          Benefit from our experienced AI professionals,
                          flexible on-demand resources, minimal upfront
                          investment along with rapid development and
                          integration capabilities.
                        </p>
                      </div>
                      <div className="absolute md:-bottom-3 md:right-10 lg:bottom-0 lg:right-10  2xl:-bottom-5 m-4 group">
                        <figure className="relative md:w-20 md:h-20 lg:w-32 lg:h-32  overflow-hidden">
                          <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                            <img
                              src={grayarrow}
                              alt="Gray Arrow"
                              className="w-10 h-10 -mr-3"
                            />
                          </div>

                          <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                            <img
                              src={pinkarrow}
                              alt="Gray Arrow"
                              className="w-10 h-10 -mr-3"
                            />
                          </div>
                        </figure>
                      </div>
                    </a>
                  </div>
                </div>
              </article>
            </div>
          </section>
        </section>

        <section className="block  -mt-10 md:hidden ">
          <section className="bg-[#EBF7FF] h-max bg-cover bg-no-repeat ">
            <div className="flex overflow-x-auto  scrollbar-hide mb-10 flex-row pt-5 gap-4  px-2  pb-20">
              <article className="bg-white scale-95  p-6  rounded-lg px-8  w-10/12 h-4/5 lg:h-auto  flex-shrink-0 ">
                <p className="text-sm text-[#666666]  font-display pb-4  pt-7 ">
                  If you are an AI tools provider,
                </p>
                <h2 className=" text-[#C62B6D] text-sm font-bold pb-5 font-display text-left">
                  Make It Easy To Choose
                  <br /> Your Tools
                </h2>
                <p className="text-sm text-[#666666] font-display pb-10 ">
                  Work with Random Walk to integrate your AI tool into our
                  platform. While our customers develop software with us, we
                  will recommend your AI solution.
                </p>

                <button
                  onClick={() => togglePopup("partner")}
                  className="hover:bg-[#C62B6D] hover:text-white hover:border-[#C62B6D] border-[#C62B6D]  mt-3 mb-3 font-display bg-[#C62B6D] text-white font-bold py-2 px-5 rounded text-xs "
                >
                  Become a Partner
                </button>
              </article>

              <article className="bg-white  p-4 rounded-lg  px-8 w-11/12 h-4/5 lg:h-auto  flex-shrink-0 mt-2.5">
                <p className="text-sm text-[#666666]  font-display pt-7">
                  If you are a client looking for AI capabilities,
                </p>
                <h2 className="lg:text-2xl text-left text-[#C62B6D] text-sm font-bold font-display pt-4">
                  Begin Your AI Journey
                  <br /> Now
                </h2>
                <p className="text-sm text-[#666666] font-display pb-5">
                  Let us work with you to integrate niche AI tools into your
                  day-to-day business functions like HR, marketing, and finance.
                  We also cater various industry-specific AI tools to aid
                  operations.
                </p>

                <button
                  onClick={() => togglePopup("integration")}
                  className="hover:bg-[#C62B6D] text-xs hover:text-white hover:border-[#C62B6D] border-[#C62B6D] mt-7 mb-4 font-display bg-[#C62B6D] text-white font-bold py-2 px-5 rounded text-xs"
                >
                  Create Integration Request
                </button>
              </article>
            </div>

            <div className="absolute flex   pb-20   flex-col relative -top-20 px-4">
              <a href="/ai-integration/" className="block">
                <div className="lg:ml-20 flex flex-col items-center relative  ">
                  <img
                    src={homebg4img}
                    alt="AI_Integration"
                    className="lg:w-auto mr-10 px-12 w-[300px] h-[200px]" // Set width and height in pixels
                  />

                  <img
                    src={home6bubble}
                    alt="AI_Integration_img1"
                    className="absolute w-[240px] h-[240px] lg:w-6/12 md:top-32 md:right-0 -left-20  top-60 lg:top-40 lg:right-0 "
                  />

                  <section className="flex bg-white opacity-75  translate-x-20 mr-32 border-2 border-white flex-col w-full z-40 bg-cover rounded-xl  bg-no-repeat relative mt-6">
                    <figure className="absolute -top-52 right-0 ml-6 lg:ml-0 w-20 lg:w-28 lg:-mt-16 h-auto">
                      <img
                        src={rightsideimg}
                        alt="AI_Integration_img2"
                        className="w-[350px] h-[350px]"
                      />
                    </figure>

                    <div className="flex flex-col lg:pl-24 p-5">
                      <h2 className="text-[#565656] font-display md:text-xl lg:text-3xl font-bold pb-3 lg:pb-5 text-left">
                        AI Integration
                      </h2>
                      <p className="text-[#000000] text-xs z-50 font-display md:text-xs lg:text-base  md:pb-7 md:text-left">
                        With our extensive experience, we’ve perfected the skill
                        of managing different AI tools and software. Our
                        expertise lies in seamless AI integration, including
                        ChatGPT integration & more, into various systems with
                        precision. Trust us for a smooth AI integration
                        services, which starts with AI consulting services that
                        ensures flawless implementation and deliver evident
                        results.
                      </p>
                    </div>

                    <div className="absolute right-5   -bottom-5 m-4 group">
                      <div className="relative w-10 h-10    overflow-hidden">
                        <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                          <img
                            src={grayarrow}
                            alt="Gray Arrow"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "-12px",
                            }} // Set width and height in pixels
                          />
                        </div>

                        <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                          {/* <img
                            src={pinkarrow}
                            alt="Pink Arrow"
                            className="w-5 h-5 -mr-3"
                          /> */}
                          <img
                            src={pinkarrow}
                            alt="Pink Arrow"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "-12px",
                            }} // Set width and height in pixels
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </a>
            </div>
          </section>

          <div className="relative w-full h-0 -translate-y-full">
            <div className="triangle3 scale-x-100"></div>
          </div>
          <div className="relative w-full h-0 -translate-y-full">
            <div className="triangle4  scale-x-100"></div>
          </div>

          <section className="  bg-[#FFEBF4]  mt-2 h-[600px] bg-cover bg-no-repeat  ">
            <article className=" relative  text-grayy   2xl:pl-12    2xl:translate-y-[1000px]  bg-no-repeat bg-cover  top-3 px-4">
              <a href="/ai-workshop-for-corporates/" className="block">
                <div className="flex flex-col items-center justify-center">
                  <figure className=" -mt-16 lg:mr-10 md:px-12 ">
                    <img
                      src={homebg5img}
                      alt="Learn_Corporate_AI_Fundamentals"
                      className=" w-[300px] h-[200px] ml-0"
                    />
                  </figure>

                  <div className="relative flex bg-white opacity-75  -translate-x-5 z-40 flex-col w-full rounded-xl border-white border-b border-t  mt-5">
                    <figure className="absolute left-0 -top-40 w-24 lg:w-40 -mt-5 h-auto ">
                      {/* <img
                        src={leftsideimg}
                        alt="Learn_Corporate_AI_Fundamentals_img2"
                        className="w-24 h-80"
                      /> */}
                      <img
                        src={leftsideimg}
                        alt="Learn_Corporate_AI_Fundamentals_img2"
                        style={{ width: "96px", height: "320px" }}
                      />
                    </figure>
                    <img
                      src={home5bubble}
                      alt="Learn_Corporate_AI_Fundamentals_img1"
                      className="absolute w-[300px] h-[300px] translate-x-16  top-5"
                    />

                    <div className="flex flex-col lg:pl-40 p-5 pl-12 lg:py-16 ">
                      <h2 className="font-display text-xl  text-[#565656] lg:text-3xl font-bold pb-3 lg:pb-5 text-left">
                        Learn Corporate AI Fundamentals
                      </h2>
                      <p className="text-xs font-display md:text-xs text-[#000000] lg:text-sm  md:pb-7 md:text-left">
                        Random Walk’s AI learning endeavors to unravel the
                        mysteries surrounding AI, ensuring it’s not a black box.
                        We offer enhanced learning through industry-oriented
                        sessions, employing a seamless mix of learning models.
                        Explore our AI workshops and corporate AI training to
                        empower your employees with valuable skills. Our AI
                        Training and AI consulting services for executives
                        ensures a straightforward and effective learning
                        experience.
                      </p>

                      <div className="absolute -bottom-3 -right-3 m-4 group">
                        <div className="relative w-10 h-10 2  overflow-hidden">
                          <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                            <img
                              src={grayarrow}
                              alt="Gray Arrow"
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "-12px",
                              }}
                            />
                          </div>

                          <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                            <img
                              src={pinkarrow}
                              alt="Gray Arrow"
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "-12px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </article>
          </section>

          <div className="relative w-full h-0 -translate-y-full">
            <div className="triangle3 scale-x-100"></div>
          </div>
          <div className="relative w-full h-0 -translate-y-full ">
            <div className="triangle5  scale-x-100"></div>
          </div>
          <section className="  bg-[#EFECFF]   h-max bg-cover bg-no-repeat mt-2 ">
            <article className="text-grayy    2xl:pl-12  2xl:translate-y-[1400px]  bg-no-repeat bg-cover pb-20">
              <a href="/ai-managed-services/" className="block">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex flex-col items-center py-0 px-4">
                    <figure className="px-12">
                      <img
                        src={homebg6img}
                        alt="AI_Integration_img"
                        className="w-[400px] h-[300px]"
                      />
                    </figure>

                    <div className="relative flex opacity-75 z-20 bg-white  translate-x-4 flex-col bg-cover bg-no-repeat lg:w-full rounded-l-xl border-white border-b border-t mt-20 ">
                      <figure className="absolute md:-mt-0 lg:-mt-16 right-0 -top-72  lg:ml-0 w-24 lg:w-28 h-auto">
                        <img
                          src={rightsideimg}
                          alt="AI_Integration_img2"
                          style={{
                            width: "96px",
                            height: "384px",
                            marginTop: "40px",
                          }}
                        />
                      </figure>

                      <div className="flex flex-col lg:pl-20 p-5 ">
                        <img
                          src={home6bubble}
                          alt="AI_Integration_img1"
                          style={{
                            position: "absolute",
                            width: "300px", // Adjust this based on your parent container's width
                            height: "300px",
                            left: "-90px",
                            top: "20px",
                          }}
                        />

                        <h2 className="font-display  text-[#565656] text-xl lg:text-3xl font-bold pb-3 lg:pb-5 text-left">
                          AI Managed Services
                        </h2>
                        <p className="text-xs font-display text-[#000000]  text-xs lg:text-sm max-w-sm pb-7 text-left">
                          Benefit from our experienced AI professionals,
                          flexible on-demand resources, minimal upfront
                          investment along with rapid development and
                          integration capabilities.
                        </p>
                        <div className="absolute -bottom-3 lg:-right-3 2xl:right-1 m-4 group">
                          <div className="relative w-10 h-10 2  overflow-hidden">
                            <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                              <img
                                src={grayarrow}
                                alt="Gray Arrow"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "-12px",
                                }}
                              />
                            </div>

                            <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                              <img
                                src={pinkarrow}
                                alt="Gray Arrow"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "-12px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </article>
          </section>
        </section>
        <h1 className="lg:text-4xl  md:text-3xl font-bold  text-3xl  py-10 px-5 md:py-10 md:px-10 text-[#666666]">
          Products
        </h1>

        <section className="hidden md:flex space-x-4 md:px-10 md:pb-5 ">
          <article className="w-1/3 p-10 relative text-white bg-gradient_background7 rounded-xl text-left">
            <a href="/ai-readiness-index/" className="block">
              <header>
                <h2 className="lg:text-2xl md:text-xl font-semibold pb-3 text-left">
                  AI Readiness and <br />
                  Digital Maturity <br /> Index
                </h2>
              </header>
              <p className="md:text-sm ">
                Identify gaps between your AI aspirations and current digital
                capabilities. Get actionable insights with a prioritized roadmap
                to bridge those gaps. Empower leaders to make data-driven
                decisions on AI adoption and digital transformation. <br />
                <br />
                Discover your AI readiness in just 15 minutes with our AI
                Readiness & Digital Maturity Assessment tool.
              </p>

              <figure className="absolute md:-bottom-10 md:-right-8 m-4 group">
                <div className="relative md:w-28 md:h-28 overflow-hidden">
                  {/* First animation (hover moves out to the right) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      style={{
                        width: "32px",
                        height: "32px",
                        marginRight: "-12px",
                      }}
                    />
                  </div>

                  {/* Second animation (hover moves into view from the left) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      style={{
                        width: "32px",
                        height: "32px",
                        marginRight: "-12px",
                      }}
                    />
                  </div>
                </div>
              </figure>
            </a>
          </article>

          <article className="w-1/3 p-10 relative bg-gradient_background7 text-white rounded-xl text-left">
            <a href="/brandcut/" className="block">
              <h2 className="lg:text-2xl md:text-xl lg:pb-[70px] font-semibold md:pb-2 text-left">
                BrandCut
              </h2>
              <p className="md:text-sm">
                Brand Sponsorship Analytics Platform powered by AI, to measure
                the value you are getting from your sponsorship spend. Analyze
                relevant metrics of brand logo visibility and strategize your
                marketing spend in three simple steps in 5 minutes.
              </p>

              <figure className="absolute md:-bottom-10 md:-right-8 m-4 group">
                <div className="relative md:w-28 md:h-28 overflow-hidden">
                  {/* First animation (hover moves out to the right) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      style={{
                        width: "32px",
                        height: "32px",
                        marginRight: "-12px",
                      }}
                    />
                  </div>

                  {/* Second animation (hover moves into view from the left) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      style={{
                        width: "32px",
                        height: "32px",
                        marginRight: "-12px",
                      }}
                    />
                  </div>
                </div>
              </figure>
            </a>
          </article>

          <article className="w-1/3 p-10 relative bg-gradient_background7 text-white rounded-xl text-left">
            <a href="/fortunecookie/" className="block">
              <h2 className="lg:text-2xl md:text-xl lg:pb-[70px] font-semibold md:pb-2 text-left">
                AI Fortune Cookie
              </h2>
              <p className="md:text-sm">
                Fortune Cookie is a cutting-edge Secure Knowledge Model
                reshaping organizational data handling. By seamlessly
                integrating unstructured and structured data, it empowers
                efficient data organization, retrieval, and insightful
                decision-making.
              </p>

              <figure className="absolute md:-bottom-10 md:-right-8 m-4 group">
                <div className="relative md:w-28 md:h-28 overflow-hidden">
                  {/* First animation (hover moves out to the right) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      style={{
                        width: "32px",
                        height: "32px",
                        marginRight: "-12px",
                      }}
                    />
                  </div>

                  {/* Second animation (hover moves into view from the left) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      className="w-8 h-8 -mr-3"
                    />
                  </div>
                </div>
              </figure>
            </a>
          </article>
        </section>

        <section className="md:hidden flex scrollbar-hide overflow-x-auto px-5 gap-4">
          <article className="w-11/12 p-5 relative text-white bg-gradient_background7 rounded-xl text-left flex-shrink-0">
            <a href="/ai-readiness-index/" className="block">
              <h2 className="lg:text-3xl text-left text-xl pb-3">
                AI Readiness and Digital Maturity Index
              </h2>
              <p className="text-sm">
                Identify gaps between your AI aspirations and current digital
                capabilities. Get actionable insights with a prioritized roadmap
                to bridge those gaps. Empower leaders to make data-driven
                decisions on AI adoption and digital transformation. <br />
                <br />
                Discover your AI readiness in just 15 minutes with our AI
                Readiness & Digital Maturity Assessment tool.
              </p>

              {/* Arrow animation */}
              <figure className="absolute bottom-0 right-1 m-1 group">
                <div className="relative w-10 h-10 overflow-hidden">
                  {/* First arrow animation (moves out to the right on hover) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "-12px",
                      }}
                    />
                  </div>

                  {/* Second arrow animation (moves into view from the left) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "-12px",
                      }}
                    />
                  </div>
                </div>
              </figure>
            </a>
          </article>

          <article className="w-11/12 p-5 relative bg-gradient_background7 text-white rounded-xl text-left flex-shrink-0">
            <a href="/brandcut/" className="block">
              <h2 className="lg:text-3xl text-xl pb-3 text-left lg:pb-[70px]">
                BrandCut
              </h2>
              <p className="text-sm">
                Brand Sponsorship Analytics Platform powered by AI, to measure
                the value you are getting from your sponsorship spend. Analyze
                relevant metrics of brand logo visibility and strategize your
                marketing spend in three simple steps in 5 minutes.
              </p>

              {/* Arrow animation */}
              <figure className="absolute bottom-0 right-1 m-1 group">
                <div className="relative w-10 h-10 overflow-hidden">
                  {/* First arrow animation (moves out to the right on hover) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "-12px",
                      }}
                    />
                  </div>

                  {/* Second arrow animation (moves into view from the left) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "-12px",
                      }}
                    />
                  </div>
                </div>
              </figure>
            </a>
          </article>

          <article className="w-11/12 p-5 relative bg-gradient_background7 text-white rounded-xl text-left flex-shrink-0">
            <a href="/fortunecookie/" className="block">
              <h2 className="lg:text-3xl text-left text-xl pb-3 lg:pb-[70px]">
                AI Fortune Cookie
              </h2>
              <p className="text-sm">
                Fortune Cookies is a cutting-edge Secure Knowledge Model
                reshaping organizational data handling. By seamlessly
                integrating unstructured and structured data, it empowers
                efficient data organization, retrieval, and insightful
                decision-making.
              </p>

              {/* Arrow animation */}
              <figure className="absolute bottom-0 right-1 m-1 group">
                <div className="relative w-10 h-10 overflow-hidden">
                  {/* First arrow animation (moves out to the right on hover) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "-12px",
                      }}
                    />
                  </div>

                  {/* Second arrow animation (moves into view from the left) */}
                  <div className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0">
                    <img
                      src={arrow}
                      alt="Pink Arrow"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "-12px",
                      }}
                    />
                  </div>
                </div>
              </figure>
            </a>
          </article>
        </section>

        {isSuccessOpen && (
          <section className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <a
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </a>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">
                Thank you for your interest in becoming a partner!
              </p>
              <button
                id="close"
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModal}
              >
                Close
              </button>
            </div>
          </section>
        )}
        {isSuccessOpenintegration && (
          <section className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <a
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModalintegration}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </a>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">Your request has been sent successfully.</p>
              <button
                id="close"
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModalintegration}
              >
                Close
              </button>
            </div>
          </section>
        )}
        <h2 className="lg:text-4xl  md:text-2xl  d font-bold md:my-5 py-10 md:py-0 px-5 text-3xl  md:px-10  md:pb-10 text-[#666666] text-left">
          Our Blogs
        </h2>

        <section className="grid max-md:hidden text-[#666666] lg:grid-cols-5 md:px-10 md:grid-cols-5 sm:grid-cols-2 ss:grid-cols-1 gap-5 px-4 h-[28rem] overflow-hidden md:pt-32 -translate-y-1/3 md:mt-10">
          {blogs && blogs.length > 0 ? (
            blogs.map((blog, index) => (
              <Link
                key={index}
                to={`/blog/${blog?.title ? titleToSlug(blog.title) : blog.id}/`}
                className="group hover:-translate-y-1 transform transition-all duration-300"
              >
                <article
                  className={`group-hover:-translate-y-12 transform w-full relative rounded-xl overflow-hidden drop-shadow-md flex flex-col justify-between transition-all duration-300 ease-in-out h-80
                ${
                  index === 1
                    ? "bg-[#EBF7FF]"
                    : index < 1
                    ? "bg-[#a9b4df]"
                    : "bg-[#FFEBF4]"
                }
              `}
                >
                  <article className="p-5 transition-transform duration-300 ease-in-out transform">
                    <h2 className="font-bold text-[#666666]  text-left md:text-xs lg:line-clamp-3 pb-5">
                      {blog?.title || "Untitled"}
                    </h2>
                    <p className="text-[#666666] text-sm md:line-clamp-5 lg:line-clamp-10">
                      {blog?.description || "No description available"}
                    </p>
                  </article>
                </article>
               
                <article className="overflow-hidden rounded-lg transform transition-all group-hover:-translate-y-full">
                  {blog?.imageUrl ? (
                    <picture>
                      {/* WebP Image for Modern Browsers */}
                      <source
                        srcSet={blog.imageUrl.replace(
                          /\.(jpg|jpeg|png)$/,
                          ".webp"
                        )}
                        type="image/webp"
                        sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
                      />
                      {/* Fallback image for browsers that don't support WebP */}
                      <source
                        srcSet={blog.imageUrl}
                        type="image/jpeg"
                        sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
                      />
                      {/* Image with lazy loading */}
                      <img
                        src={blog.imageUrl}
                        alt={blog?.title || "Blog image"}
                        className="object-cover w-[384px] h-[160px]"
                        loading="lazy"
                       
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "fallback-image-url";
                        }}
                      />
                    </picture>
                  ) : (
                    <div className="w-full h-40 bg-gray-200 flex items-center justify-center">
                      <span className="text-[#666666]">No image</span>
                    </div>
                  )}
                </article>
              </Link>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </section>

        <section className="flex md:hidden scroll-hide gap-5 overflow-x-auto px-4 text-[#666666] h-[28rem] md:pt-32">
          {blogs && blogs?.length > 0 ? (
            blogs
              .slice(0, 5) // Limit to 5 recent blogs
              .map((blog, index) => (
                <Link
                  key={blog.id}
                  to={`/blog/${titleToSlug(blog.title)}/`}
                  className="min-w-[16rem] md:min-w-[18rem]"
                >
                  <article
                    className={`w-full relative rounded-xl overflow-hidden drop-shadow-md flex flex-col h-80 
            ${
              index === 1
                ? "bg-[#EBF7FF]"
                : index < 1
                ? "bg-[#a9b4df]"
                : "bg-[#FFEBF4]"
            }
          `}
                  >
                 
                    <article className="rounded-t-xl overflow-hidden">
                      <picture>
                        {/* WebP Image for Modern Browsers */}
                        <source
                          srcSet={blog.imageUrl.replace(
                            /\.(jpg|jpeg|png)$/,
                            ".webp"
                          )}
                          type="image/webp"
                          sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
                        />
                        {/* Fallback image for browsers that don't support WebP */}
                        <source
                          srcSet={blog.imageUrl}
                          type="image/jpeg"
                          sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
                        />
                        {/* Image with lazy loading */}
                        <img
                          className="object-cover w-[400px] h-[160px]"
                          loading="lazy"
                          src={blog.imageUrl}
                          alt={blog.title}
                         
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "fallback-image-url"; // Specify a fallback image URL
                          }}
                        />
                      </picture>
                    </article>

                    <article className="p-5 ">
                      <h2 className="font-bold text-[#666666] text-left text-xs md:text-sm line-clamp-5 pb-2">
                        {blog.title}
                      </h2>

                      <p className="text-[#666666] text-xs md:text-sm line-clamp-3">
                        {blog.description}
                      </p>
                    </article>
                  </article>
                </Link>
              ))
          ) : (
            <p>Loading...</p>
          )}
        </section>


        <Popup utmContent={"homepage"} />
      </div>
    </>
  );
};
export default Home;
